import { useState } from 'react';
import { Link } from 'react-scroll';
import { Helmet } from 'react-helmet';

import BgIndonesia from '../images/bg-indonesia.jpg'
import Siti from '../images/siti mamduhah.svg'
import Biografi from '../images/Foto Biografi.jpg'
import Pas from '../images/pekerjaan.jpg'
import Visi from '../images/VISI.png'
import Misi from '../images/MISI.png'
import Kontak from '../images/kontak1.jpg'
import IcIg from '../images/ic-ig.svg'
import Icfb from '../images/ic-fb.svg'
import Ictiktok from '../images/ic-tiktok.svg'
import Ictwiter from '../images/ic-twiter.svg'
import Logo from '../images/logo.svg'
import Hamburger from '../images/hamburger.svg'
import Close from '../images/ic-close.svg'
import LogoPAN from '../images/logo pan.jpg'
import NamaIbuSiti from '../images/NAMA IBU SITI.jpg'


const V1 = () => {
    const [show, setShow] = useState(false);
    const onClick = () => {
        if (show === false) {
            setShow(true)
        } else {
            setShow(false)
        }
    }
    const showNav = 'flex flex-col items-center space-y-3 text-lg lg:hidden pb-5 h-56 overflow-hidden transition-all duration-500'
    const hiddenNav = 'flex flex-col items-center space-y-3 text-lg lg:hidden h-0 overflow-hidden transition-all duration-500'

    const linkStyle = {
        cursor: "pointer", // Ganti dengan jenis kursor yang Anda inginkan
    };

    return (
        <>
            <Helmet >
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0 " />
                <meta name='keyword' content='PAN, pan, Teh Mamam, Siti Mamduhah, Maruf Amin' />
                <meta name="description" content="Bersama Teh Mamam, untuk mewujudkan Anggota DPR RI sebagai lembaga yang Professional, Transparan dan Amanah sebagai wadah aspirasi masyarakat untuk Kesejahteraan dan Berkeadilan." />
                <meta property="og:title" content="Siti Mamduhah" />
                <meta property="og:description" content="Bersama Teh Mamam, untuk mewujudkan Anggota DPR RI sebagai lembaga yang Professional, Transparan dan Amanah sebagai wadah aspirasi masyarakat untuk Kesejahteraan dan Berkeadilan." />
                <meta property="og:image" content={Siti} />
                <title>Siti Mamduhah</title>
                <link rel="icon" href={LogoPAN} />
            </Helmet>

            <nav className='fixed w-full text-white z-50 backdrop-blur-sm bg-custom-blue/70 transition-all duration-1000 '>
                <div className='h-16 w-full flex items-center justify-between px-5 lg:px-10'>
                    <div className='h-10 w-1/2 lg:w-[20%]'>
                        <img src={Logo} alt="Logo" className='h-full w-full object-contain' />
                    </div>
                    <div className='items-center space-x-3 text-lg hidden lg:flex'>
                        <Link
                            activeClass='font-bold'
                            to='beranda' spy={false}
                            smooth={true}
                            offset={-70}
                            style={linkStyle}
                            duration={500}>Beranda</Link>

                        <Link
                            activeClass='font-semibold'
                            to='biografi'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            style={linkStyle}
                            duration={500}>Biografi</Link>

                        <Link
                            activeClass='font-semibold'
                            to='riwayat'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            style={linkStyle}
                            duration={500}>Riwayat</Link>

                        <Link
                            activeClass='font-semibold'
                            to='visiMisi'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            style={linkStyle}
                            duration={500}>Visi Dan Misi</Link>

                        <Link
                            activeClass='font-semibold'
                            to='contact'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            style={linkStyle}
                            duration={500}>Kontak Kami</Link>
                    </div>
                    <button type='button' className='lg:hidden flex items-center justify-center relative' onClick={onClick}>
                        <img src={Hamburger} alt="Hamburger" className={show ? 'scale-0 rotate-180 transition-all duration-500' : 'scale-100 rotate-0 transition-all duration-500'} />
                        <img src={Close} alt="Icon Close" className={show ? 'absolute scale-100 rotate-0 transition-all duration-500' : 'absolute scale-0 -rotate-180 transition-all duration-500'} />
                    </button>
                </div>
                <div className={show ? showNav : hiddenNav}>
                    <div className='h-1 w-[90%] bg-white rounded-full mx-auto'></div>
                    <Link
                        activeClass='font-semibold'
                        to='beranda' spy={false}
                        smooth={true}
                        offset={-70}
                        style={linkStyle}
                        duration={500}>Beranda</Link>

                    <Link
                        activeClass='font-semibold'
                        to='biografi'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        style={linkStyle}
                        duration={500}>Biografi</Link>

                    <Link
                        activeClass='font-semibold'
                        to='riwayat'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        style={linkStyle}
                        duration={500}>Riwayat</Link>

                    <Link
                        activeClass='font-semibold'
                        to='visiMisi'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        style={linkStyle}
                        duration={500}>Visi Dan Misi</Link>

                    <Link
                        activeClass='font-semibold'
                        to='contact'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        style={linkStyle}
                        duration={500}>Kontak Kami</Link>
                </div>
            </nav>

            {/* Welcome Page */}
            <div id='beranda' className='w-full h-screen bg-custom-blue flex flex-col lg:flex-row items-center relative overflow-hidden rounded-br-[50px]'>
                <div className='lg:h-screen w-full lg:w-[60%] bg-cover bg-bottom relative flex items-end' style={{ backgroundImage: `url(${BgIndonesia})` }}>
                    <img src={Siti} alt="Siti Mamduhah" className='h-[80%] lg:h-[90%] object-contain' />
                    <img src={NamaIbuSiti} alt="Siti Mamduhah" className='md:hidden absolute top-28 right-5 lg:bottom-28 lg:right-1/2 w-[40%] object-contain rounded-b-md' />
                    <img src={NamaIbuSiti} alt="Siti Mamduhah" className='hidden md:block lg:hidden absolute bottom-16 right-5 lg:bottom-28 lg:right-1/2 w-[40%] object-contain rounded-b-md' />
                    <img src={NamaIbuSiti} alt="Siti Mamduhah" className='hidden lg:block absolute bottom-16 right-5 rounded-b-md w-[40%] object-contain' />
                </div>
                <div className='w-full px-5 lg:px-0 lg:w-[40%] text-white lg:pr-16 mt-10 lg:mt-0'>
                    <h1 className='text-2xl lg:text-4xl font-bold'>Mari Bergabung</h1>
                    <h1 className='text-2xl lg:text-4xl font-bold mb-3'>Bersama Teh Mamam</h1>
                    <p className='text-xs md:text-sm lg:text-base'>
                        Bersama untuk mewujudkan Anggota DPR RI sebagai lembaga yang Professional, Transparan dan Amanah sebagai wadah aspirasi masyarakat untuk Kesejahteraan dan Berkeadilan.
                    </p>
                    <a href='https://wa.me/6285217164570'>
                        <div className='px-3 py-2 bg-white text-custom-blue font-bold rounded-md mt-5 lg:mt-10 w-fit'>
                            Berikan Dukungan
                        </div>
                    </a>
                </div>
            </div>

            {/* Biografi */}
            <div id='biografi' className='lg:h-screen w-full flex flex-col lg:flex-row items-center pt-10 lg:pt-0 space-y-10 lg:space-y-0'>
                <div className='w-full lg:w-[65%] lg:pr-10 px-5 lg:px-10'>
                    <h1 className='text-3xl font-bold text-custom-blue mb-5 text-center lg:text-start'>Biografi</h1>
                    <p className='text-xs lg:text-base lg:font-semibold text-center lg:text-start'>
                        Hj. Siti Mamduhah Maruf Amin, S.E., M.Si., atau biasa dipanggil Teh Mamam merupakan putri Wakil Presiden RI Ke-13 Prof. Dr. (H.C.) K.H. Maruf Amin. Lahir di Jakarta, 22 Januari 1969, berbekal pengalaman serta ingin mengikuti jejak langkah abah mengabdikan diri untuk negeri yang akan mewakili masyarakat Dapil Jawa Barat XI (Kab. Garut, Kab. Tasikmalaya, Kota Tasikmalaya) untuk bersuara di Senayan. Teh Mamam dengan Partai Amanat Nasional akan memastikan masyarakat dari dapil JABAR XI bahwa pemerataan, kesejahteraan, Pendidikan, Ekonomi dan Keadilan Sosial dapat dinikmati oleh seluruh rakyat Indonesia.
                    </p>
                </div>
                <div className='w-full lg:w-[35%]  lg:px-10'>
                    <img src={Biografi} alt="Sample" className='mx-auto' />
                </div>
            </div>

            {/* Riwayat */}
            <div id='riwayat' className='h-fit w-full flex flex-col lg:flex-row px-5 lg:px-10 space-y-10 lg:space-y-0 lg:space-x-10 pt-5 lg:pt-0'>
                {/* Pendidikan */}
                <div className='w-full lg:w-[45%] '>
                    <div className='rounded-xl drop-shadow-md shadow-md shadow-gray-300 sh p-5'>
                        <h1 className='text-xl md:text-3xl font-bold text-custom-blue mb-5 text-center'>Pendidikan</h1>
                        <ul className='text-xs md:text-sm lg:text-base ml-5'>
                            <li className='list-disc'>1981 SDN 05 PAGI JAKARTA</li>
                            <li className='list-disc'>1984 MTSN 5 CILINCING JAKARTA</li>
                            <li className='list-disc'>1987 MAN 1 FILIAL CILINCING JAKARTA</li>
                            <li className='list-disc'>1993 UNIVERSITAS BOROBUDUR JAKARTA S1 FAKULTAS EKONOMI JURUSAN MANAJEMEN PERUSAHAAN</li>
                            <li className='list-disc'>1993 STAISA JAKARTA S1 PENDIDIKAN AGAMA ISLAM</li>
                            <li className='list-disc'>2011 SEKOLAH TINGGI ILMU ADMINISTRASI YAPPAN JAKARTA S2 MAGISTER ILMU ADMINISTRASI KONSENTRASI ILMU ADMINISTRASI PENDIDIKAN</li>
                        </ul>
                    </div>
                    <img src={Pas} alt="Pan" className='w-full aspect-video object-cover object-top rounded-xl mt-5' />
                </div>

                {/* Pekerjaan */}
                <div className='w-full lg:w-[65%] rounded-xl drop-shadow-md shadow-md shadow-gray-300 p-5'>
                    <h1 className='text-xl md:text-3xl font-bold text-custom-blue mb-5 text-center'>Pekerjaan & Organisasi</h1>
                    <ul className='text-xs md:text-sm lg:text-base ml-5'>
                        <li className='list-disc'>Tenaga Pengajar di SMP-SMA-MA Al-Jihad (1991 – Sekarang)</li>
                        <li className='list-disc'>Wakil Kepala Sekolah SMP Al-Jihad (1995-1997)</li>
                        <li className='list-disc'>Kepala Sekolah SMP Al-Jihad (1997 – Sekarang)</li>
                        <li className='list-disc'>Dewan STAISA Jakarta (2011 – Sekarang)</li>
                        <li className='list-disc'>Sekertaris Anggota DPR (2000 – 2005)</li>
                        <li className='list-disc'>Wakil Ketua STIF (Sekolah Tinggi Ilmu Fiqih) Syeikh Nawawi Tanara Serang Banten (2013 – 2016)</li>
                        <li className='list-disc'>Wakil Sekertaris di KOMINFO MUI (Majelis Ulama Indonesia) Pusat (2017 – Sekarang)</li>
                        <li className='list-disc'>Pengurus Yayasan Al-Jihad Shalahuddin Al-Ayyubi Jakarta (2017 – Sekarang)</li>
                        <li className='list-disc'>Pengurus YKM (Yayasan Kesejahteraan Muslimat) Bidang Organisasi, NU Pusat (2017 – Sekarang)</li>
                        <li className='list-disc'>Pengawas Yayasan Al-Jihad Shalahuddin Al-Ayyubi (2017 – Sekarang)</li>
                        <li className='list-disc'>Wiraswasta (Sekarang)</li>
                        <li className='list-disc'>Ketua Pembina Yayasan Dewa Dewi Indonesia (Sekarang)</li>
                        <li className='list-disc'>Wasekjen Komnas Pendidikan Nasional (Sekarang)</li>
                    </ul>
                </div>
            </div>

            {/* Visi Misi */}
            <div id='visiMisi' className='w-full relative pt-16'>
                <div className='h-24 flex items-center justify-center lg:justify-end px-5 lg:px-10 pb-52 lg:pb-0'>
                    <h1 className='text-xl md:text-3xl font-bold text-custom-blue lg:mb-5'>Visi Misi & Tujuan</h1>
                </div>
                <div className='bg-custom-blue text-white lg:rounded-2xl pb-10 text-center'>
                    {/* Visi */}
                    <div className='flex flex-col lg:flex-row items-center lg:space-x-5 text-start px-5 lg:px-10'>
                        <div className='flex flex-col -mt-40'>
                            {/* <img src={LogoPANBantuRakyat} alt="Logo PAN Bantu Rakyat" className='w-1/3 mx-auto mb-3' /> */}
                            <img src={Visi} alt="Img-Visi" className='rounded-b-3xl' />
                        </div>
                        <div className='text-center lg:text-start mt-5 lg:mt-0'>
                            <h1 className='text-xl lg:text-3xl font-bold mb-5'>Visi : Mengabdi Untuk Negeri</h1>
                            <p className='font-semibold text-xs md:text-sm lg:text-base'>
                                Secara Filosofis makna tersebut dapat dijelaskan bahwa Visi ini akan membawa Jabar XI lebih bertaqwa serta religius, lebih maju untuk berdaya saing agar sejahtera dan membangun cinta masyarakat kepada NKRI.
                            </p>
                        </div>
                    </div>
                    {/* Misi */}
                    <div className='w-full flex flex-col lg:flex-row items-center lg:space-x-5 space-y-5 lg:space-y-0 mt-10 lg:mt-0'>
                        <div className='text-start px-5 lg:px-10'>
                            <h1 className='text-xl lg:text-3xl font-bold  mb-5 text-center lg:text-start'>Misi</h1>
                            <ul className='text-justify lg:text-start text-xs md:text-sm lg:text-base ml-5'>
                                <li className='list-disc'>
                                    Meningkatkan Kualitas SDM dan Kehidupan yang berakhlakul karimah serta Agamis, Berpendidikan, Berkearifan Lokal, Beradab, Bermoral, Berdaya saing, Sehat, Cerdas dan Berbudaya
                                </li>
                                <li className='list-disc'>
                                    Meningkatkan pemerataan pembangunan yang berkeadilan serta kemantapan Infrastruktur sesuai daya dukung dan daya tampung lingkungan serta fungsi ruang melalui penguatan desa sebagai pusat pertumbuhan ekonomi kerakyatan berbasis pertanian dan pariwisata yang berdaya saing dengan memaksimalkan Sumber Daya Alam secara berkelanjutan sehingga meningkatkan ekonomi dan mensejahterakan masyarakat.
                                </li>
                                <li className='list-disc'>
                                    Meningkatkan investasi yang kondusif untuk pengembangan usaha dan penciptaan lapangan kerja melalui pengembangan skala Lokal, Nasional, Regional dan Global.
                                </li>
                            </ul>
                        </div>
                        {/* <div className='flex flex-col bg-white px-5 lg:px-10 py-5'> */}
                        {/* <img src={LogoPANBantuRakyat} alt="Logo PAN Bantu Rakyat" className='w-1/3 mx-auto mb-3' /> */}
                        <img src={Misi} alt="Img-Visi" className='w-full lg:rounded-l-3xl' />
                        {/* </div> */}
                        {/* <img src={Misi} alt="Img-Misi" /> */}
                    </div>

                    {/* Tujuan */}
                    <div className='w-full px-5'>
                        <h1 className='text-xl lg:text-3xl font-bold mb-5 text-center mt-10'>Tujuan</h1>
                        <p className='w-full lg:w-[850px] text-center lg:mx-auto text-xs md:text-sm lg:text-base'>
                            Mewujudkan Anggota DPR RI sebagai lembaga yang Professional, Transparan dan Amanah sebagai wadah aspirasi masyarakat untuk Kesejahteraan dan Berkeadilan.
                        </p>
                    </div>
                </div>
            </div>

            {/* Vidio */}
            <div className='w-full relative bg-custom-gray pt-5 lg:pt-16'>
                <div className='w-[95%] lg:w-[70%] mx-auto pb-20 md:pb-32 lg:pb-40'>
                    {/* <video controls="true" className='w-full aspect-video rounded-xl'>
              <source src="https://youtu.be/lFMsSEd7wKw?si=cAQzzMR2k_Adoehm" type="video/mp4" />
            </video> */}
                    <iframe className='w-full aspect-video rounded-3xl' src="https://www.youtube.com/embed/lFMsSEd7wKw?si=oHEcX5pqBYHRfAXK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='absolute bottom-0'>
                    <path fill="#ffffff" fill-opacity="1" d="M0,160L48,186.7C96,213,192,267,288,272C384,277,480,235,576,208C672,181,768,171,864,176C960,181,1056,203,1152,218.7C1248,235,1344,245,1392,250.7L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
                    </path>
                </svg>
            </div>

            {/* Contact */}
            <div id='contact' className='w-full pb-16 pt-10'>
                <h1 className='text-xl md:text-3xl font-bold text-custom-blue mb-5 text-center'>Kontak</h1>
                <div className='w-full flex flex-col lg:flex-row px-5 lg:px-10 lg:space-x-5 space-y-5 lg:space-y-0'>
                    <div className='w-full lg:w-1/2 flex flex-col md:flex-row lg:flex-col md:items-center'>
                        <img src={Kontak} alt="img-Kontak" className='w-full md:w-1/2 lg:w-[60%] aspect-potret object-cover rounded-xl mx-auto' />
                        {/* Sosmed */}
                        <div className='w-full md:w-1/2 lg:w-full flex flex-col items-center space-y-3'>
                            <h1 className='text-2xl font-bold text-black my-5'>Sosial Media</h1>
                            <a href='https://instagram.com/sitimamduhah_marufamin?igshid=MzRlODBiNWFlZA==' className='flex items-center space-x-3 font-semibold hover:scale-105 transition-all duration-300'>
                                <img src={IcIg} alt="Icon Ig" />
                                <p>sitimamduhah_marufamin</p>
                            </a>
                            <a href='https://www.facebook.com/profile.php?id=100088045885447' className='flex items-center space-x-3 font-semibold hover:scale-105 transition-all duration-300'>
                                <img src={Icfb} alt="Icon Facebook" />
                                <p>Siti Mamduhah Ma’ruf Amin</p>
                            </a>
                            <a href='https://www.tiktok.com/@sitimamduhahmarufamin?_t=8gpmuh0XG91&_r=1' className='flex items-center space-x-3 font-semibold hover:scale-105 transition-all duration-300'>
                                <img src={Ictiktok} alt="Icon Ig" />
                                <p>@sitimamduhahmarufamin</p>
                            </a>
                            <a href='https://x.com/siti_mamduhah?s=20' className='flex items-center space-x-3 font-semibold hover:scale-105 transition-all duration-300'>
                                <img src={Ictwiter} alt="Icon Ig" />
                                <p>@siti_mamduhah</p>
                            </a>
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2 p-3 py-5 lg:py-0 lg:p-10 shadow-md shadow-gray-300 rounded-xl'>
                        <h1 className='text-2xl font-bold text-black mb-5 text-center'>Hubungi Kami</h1>
                        <p className='text-center'>email : sitimamduhahmarufamin@gmail.com</p>
                        <div className=' flex flex-col space-y-5'>
                            <input type="text" name="" id="" className='px-3 py-2 outline-none border-2 focus:border-2 focus:border-custom-blue border-gray-300 rounded-full w-full' placeholder='Nama' />
                            <input type="text" name="" id="" className='px-3 py-2 outline-none border-2 focus:border-2 focus:border-custom-blue border-gray-300 rounded-full w-full' placeholder='Email' />
                            <textarea cols="20" rows="10" className='px-3 py-2 outline-none border-2 focus:border-2 focus:border-custom-blue border-gray-300 rounded-xl w-full' placeholder='Apa yang bisa kami bantu..?'></textarea>
                        </div>
                        <button type="submit" className='font-bold px-3 py-2 w-full mt-10 bg-custom-blue text-white rounded-full'>Kirim Email</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default V1